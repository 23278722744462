
.chakra-modal__close-btn.css-1y1p040{
    display: none;
}

.rec.rec-pagination{
    display: none;
}

.rec.rec-arrow{
    box-shadow: unset !important;
    background: none !important;
    width: unset !important;
    height: unset !important;
    min-width: unset !important;
}

button.rec.rec-arrow:hover{
    background-color: unset !important;
    color: black !important;
}

/* square buttons */
.rec.rec-arrow {
    border-radius: 0;
}
/* round buttons on hover */
.rec.rec-arrow:hover {
    border-radius: 50%;
}
/* hide disabled buttons */
.rec.rec-arrow:disabled {
    visibility: hidden;
}
/* disable default outline on focused items */
/* add custom outline on focused items */
.rec-carousel-item:focus {
    outline: none;
    box-shadow: inset 0 0 1px 1px lightgrey;
}

/*.rec.rec-swipable{*/
/*    place-items: center;*/
/*}*/


/* Hide scrollbar for Chrome, Safari and Opera */
.example::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.modal-complete.chakra-modal__content{
    max-width: 380px !important;
}
